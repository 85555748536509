import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import IconWithBG from "@/components/elements/IconWithBG";
import { TextMuted } from "@/components/elements/Typography";
import ImportIcon from "@/components/icons/ImportIcon";
import { Checkbox } from "@/components/primitives/Checkbox";
import { Input } from "@/components/primitives/input";
import cn from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogoBox } from "@/integrations";
import { ViewItemType } from "@/pages/ModelTool/Sidebar/SidebarFactory";
import { useDataSourcesFolders } from "@/pages/ModelTool/Sidebar/data-sources/useDataSourcesFolders";

import { ExpandCollapseButton } from "./ExpandCollapseButton";

type RawView = ViewItemType["rawView"];

type RawViewItem = {
  id: string;
  name: string;
  type: "raw-view";
  folderId: string;
  data: RawView;
};

type FolderItem = {
  id: string;
  name: string;
  icon?: React.ReactElement;
  type: "folder";
  parentFolderId: string | null;
  children: (FolderItem | RawViewItem)[];
  data: {
    id: string;
    name: string;
    integrationId?: string;
  };
};

type TreeNode = FolderItem | RawViewItem;

function sortTree(tree: TreeNode[]): TreeNode[] {
  return tree
    .map((node) => {
      if (node.type === "folder") {
        const sortedChildren = sortTree(node.children);
        return { ...node, children: sortedChildren };
      }
      return node;
    })
    .sort((a, b) => {
      // Sort folders before views
      if (a.type === "folder" && b.type === "raw-view") return -1;
      if (a.type === "raw-view" && b.type === "folder") return 1;
      return a.name.localeCompare(b.name);
    });
}

function useTreeData() {
  const { dataSourcesFolders } = useDataSourcesFolders();
  return useMemo(() => {
    const foldersMap = new Map<string, FolderItem>();

    dataSourcesFolders.forEach((folder) => {
      const views = folder.children.filter(
        (item): item is ViewItemType => item.itemType === "view",
      );

      const integrationId =
        folder.folder.integrationId ||
        views.find((x) => x.rawView.integrationId != null)?.rawView
          .integrationId;

      foldersMap.set(folder.id, {
        id: folder.folder.syncId ?? folder.folder.id,
        name: folder.folder.name,
        type: "folder",
        icon: folder.icon,
        parentFolderId: null,
        children: views.map((view) => ({
          id: view.rawView.viewId,
          name: view.rawView.sourceStream,
          type: "raw-view",
          folderId: folder.id,
          data: view.rawView,
        })),
        data: {
          id: folder.folder.syncId ?? folder.folder.id,
          name: folder.folder.name,
          integrationId: integrationId ?? undefined,
        },
      });
    });

    const tree = Array.from(foldersMap.values());
    return {
      data: sortTree(tree),
      resolveFolderTrail: (folderId: string) => {
        const trail: FolderItem[] = [];
        let currentFolder = foldersMap.get(folderId);
        while (currentFolder) {
          trail.unshift(currentFolder);
          currentFolder = foldersMap.get(currentFolder.parentFolderId ?? "");
        }
        return trail;
      },
    };
  }, [dataSourcesFolders]);
}

export default function DataSourceViewsSelector(props: {
  value: RawView[];
  onChange: (models: RawView[]) => void;
}) {
  const { data: treeData, resolveFolderTrail } = useTreeData();

  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  useMountEffect(() => {
    searchInputRef.current?.focus();
  });

  const [expandedNodes, setExpanded] = useState(() => {
    const folders = props.value.flatMap((view) => {
      if (!view.syncId && !view.schema) {
        return [];
      }
      const trail = resolveFolderTrail(view.syncId ?? view.schema);
      return trail.map((folder) => folder.id);
    });
    return new Set<string>(folders);
  });

  const expand = (id: string) => {
    setExpanded((prev) => {
      if (prev.has(id)) return prev;
      return new Set([...prev, id]);
    });
  };

  const collapse = (id: string) => {
    setExpanded((prev) => {
      if (!prev.has(id)) return prev;
      return new Set([...prev].filter((x) => x !== id));
    });
  };

  const toggleExpand = (id: string) => {
    if (expandedNodes.has(id)) {
      collapse(id);
    } else {
      expand(id);
    }
  };

  const [debouncedSearchText] = useDebounce(searchText, 300);

  const filteredTreeData = useMemo(() => {
    if (!debouncedSearchText || debouncedSearchText.length < 2) {
      return treeData;
    }
    const filterTree = (nodes: TreeNode[], searchText: string): TreeNode[] => {
      return nodes
        .map((node) => {
          if (node.type === "folder") {
            const children = filterTree(node.children, searchText);
            return {
              ...node,
              children,
            };
          }
          return node;
        })
        .filter((node) => {
          if (node.type === "folder") {
            return node.children.length > 0;
          }
          return node.name.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    return filterTree(treeData, debouncedSearchText);
  }, [treeData, debouncedSearchText]);

  useEffect(() => {
    if (!debouncedSearchText || debouncedSearchText.length < 2) {
      return;
    }
    const folderIds = new Set<string>();
    const traverse = (nodes: TreeNode[]) => {
      nodes.forEach((node) => {
        if (node.type === "folder" && node.children.length > 0) {
          folderIds.add(node.id);
          traverse(node.children);
        }
      });
    };
    traverse(filteredTreeData);
    setExpanded((prev) => {
      const newExpanded = new Set(prev);
      folderIds.forEach((id) => {
        newExpanded.add(id);
      });
      return newExpanded;
    });
  }, [filteredTreeData, debouncedSearchText]);

  const handleToggleAllViews = (isChecked: boolean, folder: FolderItem) => {
    if (isChecked) {
      const selectedIds = new Set(props.value.map((x) => x.viewId));
      const newViews = folder.children
        .filter((x): x is RawViewItem => x.type === "raw-view")
        .filter((x) => !selectedIds.has(x.id))
        .map((x) => x.data);
      props.onChange([...props.value, ...newViews]);
    } else {
      props.onChange(
        props.value.filter(
          (x) => !folder.children.some((m) => m.id === x.viewId),
        ),
      );
    }
  };

  const handleToggleView = (isChecked: boolean, view: RawViewItem) => {
    if (isChecked) {
      props.onChange([...props.value, view.data]);
    } else {
      props.onChange(props.value.filter((x) => x.viewId !== view.id));
    }
  };

  const renderTree = (nodes: TreeNode[]) => {
    return nodes.map((node) => {
      const isFolder = node.type === "folder";
      const isView = node.type === "raw-view";
      const isEmptyFolder = isFolder && node.children.length === 0;
      let isChecked = false;
      let isIndeterminate = false;
      if (isView) {
        isChecked = props.value.some((m) => m.viewId === node.id);
      }
      if (isFolder) {
        const checkedItems = node.children
          .filter((x): x is RawViewItem => x.type === "raw-view")
          .filter((x) => props.value.some((m) => m.viewId === x.data.viewId));
        isChecked = checkedItems.length > 0;
        isIndeterminate =
          isChecked && checkedItems.length < node.children.length;
      }
      return (
        <div key={node.id} className="pt-1">
          {isFolder && (
            <div
              className={cn("flex items-center gap-2", {
                "opacity-60": isEmptyFolder,
              })}
            >
              <ExpandCollapseButton
                open={expandedNodes.has(node.id)}
                disabled={isEmptyFolder}
                onToggle={() => toggleExpand(node.id)}
              />
              <label
                key={node.id}
                className="flex min-w-0 cursor-pointer items-center gap-2"
              >
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => {
                    handleToggleAllViews(e.target.checked, node);
                  }}
                  disabled={node.children.length === 0}
                  indeterminate={isIndeterminate}
                />
                {node.data.integrationId ? (
                  <IntegrationLogoBox id={node.data.integrationId} size="1xs" />
                ) : (
                  <IconWithBG
                    icon={<ImportIcon />}
                    size="1xs"
                    className="bg-gray-200 dark:bg-gray-700"
                  />
                )}
                <div className="flex w-full items-center overflow-x-hidden">
                  <div
                    className={cn("truncate", {
                      "opacity-60": node.children.length === 0,
                    })}
                  >
                    {node.name}
                  </div>
                  <TextMuted as="em" className="ml-4 shrink-0 text-sm">
                    {node.children.length}{" "}
                    {node.children.length === 1 ? "table" : "tables"}
                  </TextMuted>
                </div>
              </label>
            </div>
          )}
          {isView && (
            <label className="flex items-center gap-2">
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  handleToggleView(e.target.checked, node);
                }}
              />
              <span>{node.name}</span>
            </label>
          )}
          {node.type === "folder" && expandedNodes.has(node.id) && (
            <div className="ml-1 pl-12">{renderTree(node.children)}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="flex grow flex-col">
      <div>
        <Input
          ref={searchInputRef}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search Data Sources..."
          className="w-full"
          size="sm"
        />
      </div>

      <div className="mt-2 max-h-96 grow overflow-auto p-1">
        {renderTree(filteredTreeData)}
      </div>
    </div>
  );
}
