import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo, useState } from "react";
import type { CellProps, Column } from "react-table";

import {
  InvitationsDocument,
  InvitationsQuery,
  Role,
  useRemoveInvitationMutation,
} from "@/apollo/types";
import { Badge } from "@/components/elements/Badge";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import DefaultTable from "@/components/elements/DefaultTable";
import MenuItem from "@/components/elements/MenuItem";
import Tooltip from "@/components/elements/Tooltip";
import TableMenu from "@/components/modules/TableMenu";
import useDeleteItem from "@/hooks/useDeleteItem";
import { useUserRole } from "@/providers/account";

dayjs.extend(relativeTime);

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = `${dateFormat} HH:mm`;

type Row = InvitationsQuery["invitations"][0];

export default function InvitationTable(props: {
  data: InvitationsQuery["invitations"];
}) {
  const role = useUserRole();

  const columns = useMemo(() => {
    const cols: Column<Row>[] = [
      {
        Header: "Email",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.email}</span>;
        },
        accessor: "email",
      },
      {
        Header: "First name",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.firstName}</span>;
        },
        accessor: "firstName",
      },
      {
        Header: "Last name",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.lastName}</span>;
        },
        accessor: "lastName",
      },
      {
        Header: "Role",
        Cell: ({ row }: CellProps<Row>) => {
          return <span>{row.original.role ?? "none"}</span>;
        },
        accessor: "used",
      },
      {
        Header: "Expires",
        Cell: ({ row }: CellProps<Row>) => {
          const isExpired = dayjs(row.original.expiresAt).isBefore(dayjs());
          const formattedDateShort = dayjs(row.original.expiresAt).format(
            dateFormat,
          );
          const formattedDateLong = dayjs(row.original.expiresAt).format(
            dateTimeFormat,
          );
          if (isExpired) {
            return (
              <Tooltip content={formattedDateLong}>
                <Badge variant="danger">Expired</Badge>
              </Tooltip>
            );
          }
          return (
            <Tooltip content={formattedDateLong}>
              <div>{formattedDateShort}</div>
            </Tooltip>
          );
        },
        accessor: "expiresAt",
      },
      {
        Header: "Created",
        Cell: ({ row }: CellProps<Row>) => (
          <div>{dayjs(row.original.createdAt).format(dateFormat)}</div>
        ),
        accessor: "createdAt",
      },
    ];
    if (role === Role.Admin) {
      cols.push({
        Header: "",
        Cell: ({ row }: CellProps<Row>) => (
          <div className="flex justify-end">
            <InvitationMenu invitationId={row.original.id} />
          </div>
        ),
        accessor: "id",
      });
    }
    return cols;
  }, [role]);

  return <DefaultTable columns={columns} data={props.data} />;
}

const InvitationMenu = ({ invitationId }: { invitationId: string }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem({
    title: "invitation",
    variables: { invitationId },
    mutation: useRemoveInvitationMutation,
    refetchQueries: [{ query: InvitationsDocument }],
  });
  return (
    <>
      <ConfirmDeleteModal
        title="invitation"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <MenuItem
          text="Delete invitation"
          onClick={() => setShowConfirmModal(true)}
        />
      </TableMenu>
    </>
  );
};
