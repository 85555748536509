import { EltSyncsQuery } from "@/apollo/types";
import { useViewDataSourceSlideOver } from "@/components/modules/view-data-source-slideover";

import EltSyncNotifications from "../ViewEltSync/EltSyncNotifications";
import { EltJobsCompletionStats } from "./EltJobsCompletionStats";
import { DataTable } from "./EltSyncsDataTable";

export type Row = EltSyncsQuery["eltSyncs"][0];

export default function EltSyncsTable(props: {
  syncs: EltSyncsQuery["eltSyncs"];
}) {
  const { onOpen: viewSync, getLinkProps } = useViewDataSourceSlideOver();
  const onRowClick = (
    row: Row,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    if (e.metaKey || e.ctrlKey) {
      const currentParams = new URLSearchParams(window.location.search);
      Object.entries(getLinkProps({ syncId: row.id })).forEach(
        ([key, value]) => {
          currentParams.set(key, value);
        },
      );
      window.open(`${window.location.pathname}?${currentParams.toString()}`);
      return;
    }
    viewSync({ syncId: row.id });
  };

  return (
    <div className="flex flex-col gap-8">
      <EltJobsCompletionStats />
      <EltSyncNotifications eltSyncs={props.syncs} />
      <div className="w-full max-w-screen-xl">
        <DataTable syncs={props.syncs} onRowClick={onRowClick} />
      </div>
    </div>
  );
}
