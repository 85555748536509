import { useCallback } from "react";
import {
  DialectOptions,
  FormatOptions,
  FormatOptionsWithDialect,
  bigquery as bigqueryDialect,
  formatDialect,
  postgresql as postgresqlDialect,
  snowflake as snowflakeDialect,
  sql as sqlDialect,
} from "sql-formatter";

import { useLatestValueRef } from "@/hooks/useLatestValueRef";
import { useDataWarehouseContext } from "@/providers/DataWarehouseProvider";

import { EDITOR_TAB_SIZE } from "../constants/editor";

/**
 * Get the appropriate SQL dialect from a data warehouse configuration
 * @param integrationId data warehouse integration id
 * @returns SQL language dialect
 */
function getSQLDialect(integrationId?: string): DialectOptions {
  switch (integrationId) {
    case "bigquery":
    case "weld-bigquery": {
      return bigqueryDialect;
    }
    case "snowflake": {
      return snowflakeDialect;
    }
    case "postgres": {
      return postgresqlDialect;
    }
    default: {
      return sqlDialect;
    }
  }
}

const defaultFormatOptions: Partial<FormatOptions> = {
  linesBetweenQueries: 1,
  keywordCase: "lower",
  tabWidth: EDITOR_TAB_SIZE,
  useTabs: false,
};

type UseFormatSQLQueryOptions = {
  onError?: (error: unknown) => void;
};

/**
 * Enhances the `formatWeldSQL()` utility function and set the appropriate SQL dialect
 * based on the current data warehouse configuration.
 * @param options format options
 * @returns format function
 */
export function useFormatWeldSQL(options?: UseFormatSQLQueryOptions) {
  const optionsRef = useLatestValueRef(options);

  const dwh = useDataWarehouseContext();
  return useCallback(
    (weldSQL: string) => {
      try {
        return formatWeldSQL(weldSQL, {
          dialect: getSQLDialect(dwh.integration.id),
        });
      } catch (error) {
        optionsRef.current?.onError?.(error);
        return weldSQL;
      }
    },
    [optionsRef, dwh.integration.id],
  );
}

//Regex paterns to find all references
const findAllWeldReferencesRegex = /\{\{(.*?)}\}/g;
const findAllBigQueryReferencesRegex = /`(.*?)`/g;

/**
 * Format WELD SQL
 * @param weldSql raw weld sql with WELD references
 * @param options options for the `sql-formatter` library
 * @returns a formatted SQL string
 */
function formatWeldSQL(weldSql: string, options: FormatOptionsWithDialect) {
  const weldReferences = [...weldSql.matchAll(findAllWeldReferencesRegex)].map(
    (match, index) => ({
      id: `WELD_REFERENCE_${index}`,
      originalRef: match[0],
    }),
  );
  const bigQueryReferences = [
    ...weldSql.matchAll(findAllBigQueryReferencesRegex),
  ].map((match, index) => ({
    id: `BQ_REFERENCE_${index}`,
    originalRef: match[0],
  }));

  const allReplaceable = [...weldReferences, ...bigQueryReferences];

  const sqlWithIds = allReplaceable.reduce((prev, { id, originalRef }) => {
    return prev.replace(originalRef, id);
  }, weldSql);

  const formattedSql = formatDialect(sqlWithIds, {
    ...defaultFormatOptions,
    ...options,
  });

  const formattedWithOriginalReferences = allReplaceable.reduce(
    (prev, { id, originalRef }) => {
      return prev.replace(id, originalRef);
    },
    formattedSql,
  );

  return formattedWithOriginalReferences;
}
