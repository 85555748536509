import React from "react";

import { useGetAccountIdQuery } from "@/apollo/types";
import { useMatch, useNavigate } from "@tanstack/react-location";

import * as routeHelpers from "../../router/routeHelpers";
import { useToast } from "../ToastProvider";
import { AccountProvider } from "./AccountProvider";

type RouteAwareAccountProviderProps = {
  paramName: string;
};

/**
 * Wrapper around the the AccountProvider which connects the url with
 * the current account. It takes a slug and resolves the associated account id
 * and passes it to the AccountProvider.
 *
 * @param param0
 * @returns
 */
export function RouteAwareAccountProvider({
  paramName,
  children,
}: React.PropsWithChildren<RouteAwareAccountProviderProps>) {
  const { params } = useMatch();
  const slug = params[paramName];
  const navigate = useNavigate();
  const toast = useToast();

  const [accountId, setAccountId] = React.useState<string>();

  const { data } = useGetAccountIdQuery({
    variables: {
      slug,
    },
    onError: () => {
      toast(
        "Workspace not found",
        `Workspace with slug "${slug}" not found`,
        "error",
      );
      navigateToWorkspaces();
    },
  });

  React.useEffect(() => {
    // Reset accountId when `slug` changes and let the `accountId` be resolved again.
    setAccountId(undefined);
  }, [slug]);

  React.useEffect(() => {
    if (!data) return;
    setAccountId(data.accountBySlug.id);
  }, [data]);

  const navigateToWorkspaces = React.useCallback(() => {
    navigate({
      to: routeHelpers.workspacesRoute(),
    });
  }, [navigate]);

  const handleInvalidAccount = React.useCallback(async () => {
    navigateToWorkspaces();
  }, [navigateToWorkspaces]);

  if (accountId === undefined) {
    return null;
  }

  return (
    <AccountProvider
      accountId={accountId}
      onInvalidAccount={handleInvalidAccount}
    >
      {children}
    </AccountProvider>
  );
}
