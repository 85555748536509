import React, { Fragment, ReactNode, useMemo, useState } from "react";
import { LinkWithSlug } from "routes";

import { Notification } from "@/apollo/types";
import { List } from "@/components/elements/List";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import { SuccessIcon } from "@/components/icons/graphic";
import { AlertsIcon } from "@/components/icons/outline";
import {
  useNotificationAction,
  useNotifications,
  useRemoveNotification,
} from "@/features/notifications";
import { NotificationDetailModal } from "@/features/notifications/components/NotificationDetailModal";
import cn from "@/helpers/classNames";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useToast } from "@/providers/ToastProvider";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

import TableMenu, { MenuButton } from "../TableMenu";
import { NotificationItem } from "./NotificationItem";
import { useNotificationItem } from "./useNotificationItem";

const SidebarNotificationsContext = React.createContext({
  notificationsCount: 0,
  toggleSidebar: () => {},
  hideSidebar: () => {},
});

export const useSidebarNotifications = () => {
  return React.useContext(SidebarNotificationsContext);
};

type SidebarNotificationsProps = {
  children?:
    | React.ReactNode
    | ((
        props: React.ContextType<typeof SidebarNotificationsContext>,
      ) => React.ReactNode);
};

const SidebarNotifications = (props: SidebarNotificationsProps) => {
  const [showNotifications, setShowNotifications] = useState(false);

  const { notifications, loading } = useNotifications();

  const ctx = useMemo(
    () => ({
      notificationsCount: notifications.length,
      toggleSidebar: () => {
        setShowNotifications((value) => !value);
      },
      hideSidebar: () => {
        setShowNotifications(() => false);
      },
    }),
    [notifications],
  );

  return (
    <>
      <SidebarNotificationsContext.Provider value={ctx}>
        {typeof props.children === "function"
          ? props.children(ctx)
          : props.children}

        <SlideOver
          show={showNotifications}
          onClose={() => setShowNotifications(false)}
        >
          <SlideOverCloseButton />
          <SlideOverHeader className="flex items-center space-x-2">
            <AlertsIcon className="h-5 w-5" />
            <span>Alerts</span>
          </SlideOverHeader>
          <SlideOverBody className="px-0">
            {loading && (
              <div className="flex w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            )}
            {notifications.length === 0 && !loading ? (
              <NotificationsEmptyState />
            ) : (
              <List as="div" role="list">
                {notifications.map((notification) => (
                  <NotificationRenderer
                    notification={notification}
                    key={notification.id}
                  />
                ))}
              </List>
            )}
          </SlideOverBody>
        </SlideOver>
      </SidebarNotificationsContext.Provider>
    </>
  );
};

export default SidebarNotifications;

function NotificationsEmptyState(props: React.ComponentProps<"div">) {
  return (
    <div className="my-4 flex flex-col items-center justify-center gap-4 py-10">
      <SuccessIcon className="h-20 text-white" />
      <div className="">No alerts for account.</div>
    </div>
  );
}

function NotificationRenderer(props: { notification: Notification }) {
  const itemProps = useNotificationItem(props.notification);
  const disclosure = useDisclosure();

  const { getActionProps } = useNotificationAction({
    onCompleted: (message) => toast("Success", message, "success"),
    onError: (message) => toast("Error", message, "error"),
  });
  const actionProps = getActionProps(props.notification);

  const { hideSidebar } = useSidebarNotifications();

  const toast = useToast();
  const [removeNotification] = useRemoveNotification({
    onCompleted: () => {
      toast("Alert removed", "Sync will be retried.", "success");
    },
    onError: () => {
      toast(
        "Alert not removed",
        "An error occured while queueing the sync for retry",
        "error",
      );
    },
  });

  const items: ReactNode[] = [];
  actionProps.forEach((action) => {
    items.push(
      <div className="px-1 py-1">
        <Menu.Item>
          {({ active }) => (
            <LinkWithSlug
              {...actionProps}
              onClick={(e) => {
                e.stopPropagation();
                hideSidebar();
                action?.handler?.(e);
              }}
              className={cn(
                "group flex w-full items-center rounded-md px-2 py-2 text-sm",
                {
                  "bg-gray-100 dark:bg-gray-700": active,
                  "dark:text-white": !active,
                },
              )}
            >
              {action.label}
            </LinkWithSlug>
          )}
        </Menu.Item>
      </div>,
    );
  });
  if (props.notification.isDismissable) {
    items.push(
      <div className="px-1 py-1">
        <Menu.Item>
          {({ active }) => (
            <button
              onClick={(e) => {
                e.stopPropagation();
                removeNotification(props.notification);
              }}
              className={cn(
                "group flex w-full items-center rounded-md px-2 py-2 text-sm",
                {
                  "bg-gray-100 dark:bg-gray-700": active,
                  "dark:text-white": !active,
                },
              )}
            >
              Remove alert
            </button>
          )}
        </Menu.Item>
      </div>,
    );
  }

  return (
    <>
      <NotificationItem
        {...itemProps}
        role="listitem"
        onClick={() => {
          disclosure.onOpen();
        }}
        secondaryAction={
          items.length > 0 && (
            <TableMenu button={<MenuButton icon={<EllipsisVerticalIcon />} />}>
              <div className="divide-y">
                {items.map((x, idx) => (
                  <Fragment key={idx}>{x}</Fragment>
                ))}
              </div>
            </TableMenu>
          )
        }
      />
      <NotificationDetailModal
        {...disclosure}
        notification={props.notification}
      />
    </>
  );
}
