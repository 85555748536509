import { IconButton } from "@/components/elements/Button";
import cn from "@/helpers/classNames";
import { ChevronRightIcon } from "@heroicons/react/16/solid";
import { ComponentProps } from "@tw-classed/react";

export function ExpandCollapseButton({
  open,
  onToggle,
  ...props
}: Omit<ComponentProps<typeof IconButton>, "icon"> & {
  open: boolean;
  onToggle?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <IconButton
      {...props}
      type="button"
      variant="ghost"
      size="xs"
      disabled={props.disabled}
      onClick={(e) => !props.disabled && onToggle?.(e)}
      icon={
        <ChevronRightIcon
          className={cn(
            "h-4 w-4 transition-transform",
            open && !props.disabled && "rotate-90",
          )}
        />
      }
    />
  );
}
