import { forwardRef, useMemo, useState } from "react";
import {
  ControlProps,
  MenuProps,
  OptionTypeBase,
  Props as SelectProps,
  components,
} from "react-select";

import cn from "@/helpers/classNames";

import { Button, PrimaryButton } from "./Button";
import InputContainerButton from "./InputContainer";
import Select from "./Select";

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  const {
    selectProps: { descriptionKey },
    isDisabled,
  } = props;
  const hasDescription = useMemo(
    () => !!props.options?.some((v) => v[descriptionKey]),
    [descriptionKey, props.options],
  );
  return (
    <components.Control {...props}>
      <InputContainerButton
        isDisabled={isDisabled}
        className="h-auto"
        isFocused={props.isFocused}
      >
        <div
          className={cn("flex max-h-24 w-full overflow-y-auto py-2.5", {
            "h-16": hasDescription,
            "h-auto": props.isMulti,
          })}
        >
          {children}
        </div>
      </InputContainerButton>
    </components.Control>
  );
};

const Menu = ({ children, ...props }: MenuProps<any, true>) => {
  const selectAll = () => {
    props.selectProps.onChange?.(props.options, {
      action: "select-option",
      option: props.options,
    });
  };

  const deselectAll = () => {
    props.selectProps.onChange?.([], {
      action: "deselect-option",
      option: props.options,
    });
  };

  const applySelection = () => {
    props.selectProps.onMenuClose?.();
  };

  return (
    <components.Menu {...props}>
      {props.selectProps.isLoading ? (
        <div className="p-2 text-center text-base leading-6 text-gray-400 dark:bg-gray-700">
          Loading...
        </div>
      ) : (
        <div>
          {children}
          <div className="flex w-full items-center justify-between border-t px-4 py-2 dark:border-t-gray-800 dark:bg-gray-700">
            <div className="text-sm">
              <Button variant="link" size="sm" onClick={selectAll}>
                Select all
              </Button>
              <span className="mx-2">|</span>
              <Button
                variant="link"
                size="sm"
                disabled={(props.selectProps.value ?? []).length === 0}
                onClick={deselectAll}
              >
                Deselect all
              </Button>
            </div>
            <PrimaryButton onClick={applySelection} size="sm">
              Apply selection
            </PrimaryButton>
          </div>
        </div>
      )}
    </components.Menu>
  );
};

export const AppliedMultiSelect = forwardRef(
  <OptionType extends OptionTypeBase = { label: string; value: string }>(
    props: SelectProps<OptionType, true>,
    ref: React.Ref<any>,
  ) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
    return (
      <Select
        ref={ref}
        {...props}
        value={selectedOptions}
        onChange={(options: OptionType[]) => setSelectedOptions(options)}
        menuIsOpen={isMenuOpen}
        onMenuClose={() => {
          setMenuOpen(false);
          props.onChange?.(selectedOptions, {
            action: "select-option",
            option: selectedOptions[selectedOptions.length - 1],
          });
        }}
        onMenuOpen={() => {
          setMenuOpen(true);
        }}
        components={{
          Control,
          Menu,
        }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isClearable={false}
        styles={{
          multiValueRemove: () => ({
            display: "none",
          }),
        }}
      />
    );
  },
);
