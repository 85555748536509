import { useCallback, useEffect, useState } from "react";

import { useValidateCustomFormLazyQuery } from "@/apollo/types";
import { Alert, AlertIcon, AlertTitle } from "@/components/elements/Alert";
import { Button } from "@/components/elements/Button";
import Select from "@/components/elements/Select";
import FieldLabel from "@/components/primitives/InputLabel";
import { Input } from "@/components/primitives/input";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { FacebookAdsReport, isFacebookAdsReportType } from "./FacebookAdsForm";

const INVALID_REPORT_MESSAGE = "Invalid report";

export default function FacebookAdsCustomReport(props: {
  onChangeReport: (report: any) => void;
  onRemoveReport: (report: any) => void;
  onReportInvalid: (reportId: string) => void;
  onReportValid: (reportId: string) => void;
  report: any;
  initialBreakdownOptions: { label: string; value: string }[];
  initialActionBreakdownOptions: { label: string; value: string }[];
  initialFieldOptions: { label: string; value: string }[];
  initialLevelOptions: { label: string; value: string }[];
  initialActionReportTimeOptions: { label: string; value: string }[];
  initialClickOptions: { label: string; value: string }[];
  initialAggregationOptions: { label: string; value: string }[];
  initialViewOptions: { label: string; value: string }[];
  connectionId: string;
  adAccounts?: { label: string; value: string }[];
  validationError?: string;
}) {
  const {
    report,
    initialBreakdownOptions,
    initialActionBreakdownOptions,
    initialFieldOptions,
    initialLevelOptions,
    initialActionReportTimeOptions,
    initialClickOptions,
    initialAggregationOptions,
    initialViewOptions,
    onChangeReport,
    onRemoveReport,
    validationError,
    connectionId,
    adAccounts,
    onReportValid,
    onReportInvalid,
  } = props;

  const [validActionbreakdowns, setValidActionBreakdowns] = useState<
    any[] | null
  >(null);
  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [validBreakdowns, setValidBreakdowns] = useState<any[] | null>(null);
  const [validFields, setValidFields] = useState<any[] | null>(null);
  const [validLevels, setValidLevels] = useState<any[] | null>(null);
  const [validActionReportTime, setValidActionReportTime] = useState<
    any[] | null
  >(null);
  const [validationCallError, setValidationCallError] =
    useState<boolean>(false);

  const [callValidationQuery, { loading: validationQueryLoading }] =
    useValidateCustomFormLazyQuery();

  const runValidation = useCallback(
    async (validateViaApi: boolean = false) => {
      //Call validation endpoint
      const queryData = {
        action_breakdowns: report.action_breakdowns.map((x: any) => x.value),
        breakdowns: report.breakdowns.map((x: any) => x.value),
        fields: report.fields.map((x: any) => x.value),
        levels: report.levels,
        click_window: report.click_window?.map((x: any) => x.value) ?? [],
        view_window: report.view_window?.map((x: any) => x.value) ?? [],
        action_report_time: report.action_report_time,
        aggregation: report.aggregation,
        id: report.id,
      };
      const { data, error } = await callValidationQuery({
        variables: {
          input: {
            connectionId,
            payload: JSON.stringify({
              report: queryData,
              adAccounts,
              validateViaApi,
            }),
          },
        },
      });
      //If error occurs, show warning and use initial metrics/dimensions
      if (error) {
        if (error.message === INVALID_REPORT_MESSAGE) {
          onReportInvalid(report.id);
        } else {
          setValidationCallError(true);
        }
        setValidActionBreakdowns(null);
        setValidBreakdowns(null);
        setValidFields(null);
        setValidLevels(null);
        setValidActionReportTime(null);
      }

      const result = data?.validateCustomForm.customFormValidationResult;
      if (result) {
        if (!result.apiError) {
          if (result.apiValidated) {
            setValidationSuccess(true);
            onReportValid(report.id);
            setValidationCallError(false);
          } else {
            setValidationCallError(true);
            onReportInvalid(report.id);
          }
        } else {
          setValidationCallError(result.apiErrorMessage);
          setValidationSuccess(false);
          onReportInvalid(report.id);
        }
        setValidActionBreakdowns(
          result.report.action_breakdowns.map((x: string) => ({
            label: x,
            value: x,
          })),
        );
        setValidBreakdowns(
          result.report.breakdowns.map((x: string) => ({
            label: x,
            value: x,
          })),
        );
      }
    },
    [
      adAccounts,
      callValidationQuery,
      connectionId,
      onReportInvalid,
      onReportValid,
      report.action_breakdowns,
      report.breakdowns,
      report.fields,
      report.action_report_time,
      report.id,
      report.levels,
      report.click_window,
      report.view_window,
      report.aggregation,
    ],
  );

  useEffect(() => {
    if (report.breakdowns.length > 0 || report.action_breakdowns.length > 0) {
      runValidation();
    } else {
      setValidActionBreakdowns(null);
      setValidBreakdowns(null);
      onReportValid(report.id); //If we cannot validate, we assume it is valid
    }
  }, [
    onReportValid,
    report.action_breakdowns.length,
    report.breakdowns.length,
    report.id,
    runValidation,
  ]);

  const changeReport = (report: FacebookAdsReport) => {
    onChangeReport(report);
    setValidationSuccess(false);
  };

  return (
    <div
      className="relative rounded-sm border bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700"
      key={report.id}
    >
      <div className="pb-4">
        <FieldLabel id="name" required={true}>
          Name
        </FieldLabel>
        <Input
          value={report.name}
          onChange={(e) => changeReport({ ...report, name: e.target.value })}
        />
        {isFacebookAdsReportType(report.name) && (
          <div className="text-red-500">
            {report.name} is a reserved report name and cannot be used.
          </div>
        )}
      </div>
      {/*This form element does not have any effect, having this is handled in the backend. The field is needed for UX understandability*/}
      <div className="pb-4">
        <FieldLabel id="date" required={true}>
          Required dimensions
        </FieldLabel>
        <div className="dark:bg-gray-000 rounded-sm border bg-gray-100 p-2 opacity-75 dark:border-gray-700 dark:bg-gray-800">
          <span className="rounded-sm bg-black px-2 py-1 text-xs text-white dark:bg-gray-700">
            date
          </span>
        </div>
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="breakdown" required={true}>
          Action breakdown ({report.action_breakdowns?.length ?? 0}/
          {(validActionbreakdowns || initialActionBreakdownOptions)?.length ??
            0}
          )
        </FieldLabel>
        <Select
          id="breakdown"
          isMulti={true}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) =>
            changeReport({ ...report, action_breakdowns: v })
          }
          // isOptionDisabled={() => report.dimensions?.length >= 8}
          options={validActionbreakdowns || initialActionBreakdownOptions}
          value={report.action_breakdowns}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="breakdown" required={true}>
          Breakdowns ({report.breakdowns?.length ?? 0}/
          {(validBreakdowns || initialBreakdownOptions)?.length ?? 0})
        </FieldLabel>
        <Select
          id="breakdown"
          isMulti={true}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, breakdowns: v })}
          options={validBreakdowns || initialBreakdownOptions}
          // isOptionDisabled={() => report.metrics?.length >= 10}
          value={report.breakdowns}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="fields" required={true}>
          Fields ({report.fields?.length ?? 0}/
          {initialFieldOptions?.length ?? 0})
        </FieldLabel>
        <Select
          id="fields"
          isMulti={true}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, fields: v })}
          options={validFields || initialFieldOptions}
          value={report.fields}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="level" required={true}>
          Level
        </FieldLabel>
        <Select
          id="level"
          isMulti={false}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, levels: v })}
          options={validLevels || initialLevelOptions}
          value={report.levels}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="actionReportTime" required={true}>
          Action Report Time
        </FieldLabel>
        <Select
          id="actionReportTime"
          isMulti={false}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) =>
            changeReport({ ...report, action_report_time: v })
          }
          options={validActionReportTime || initialActionReportTimeOptions}
          value={report.action_report_time}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="windowAttribution" required={true}>
          View Window Attribution
        </FieldLabel>
        <Select
          id="windowAttribution"
          isMulti={true}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, view_window: v })}
          options={initialViewOptions}
          value={report.view_window}
        />
      </div>
      <div className="pb-4">
        <FieldLabel id="clickAttribution" required={true}>
          Click Window Attribution
        </FieldLabel>
        <Select
          id="clickAttribution"
          isMulti={true}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, click_window: v })}
          options={initialClickOptions}
          value={report.click_window}
        />
      </div>
      <div className="pb-4">
        <FieldLabel htmlFor="aggregation" required={true}>
          Aggregation
        </FieldLabel>
        <Select
          id="aggregation"
          isMulti={false}
          isLoading={validationQueryLoading}
          placeholder="Select one"
          onChange={(v: any) => changeReport({ ...report, aggregation: v })}
          options={initialAggregationOptions}
          value={report.aggregation}
        />
      </div>
      <div className="right-0">
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={() => runValidation(true)}
        >
          Validate report
        </Button>
      </div>
      <div className="absolute right-0 top-0 p-2">
        <button type="button" onClick={() => onRemoveReport(report)}>
          <XMarkIcon className="w-4" />
        </button>
      </div>
      {validationSuccess ? (
        <div className="mt-2">
          <Alert status="success">
            <AlertIcon />
            Report is valid
          </Alert>
        </div>
      ) : null}
      {validationError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            {validationError}
          </Alert>
        </div>
      ) : null}
      {validationCallError === true && validationSuccess !== true ? (
        <div className="mt-2">
          <Alert status="info">
            <AlertIcon />
            Validate your report to continue
          </Alert>
        </div>
      ) : null}
      {validationCallError !== true && validationCallError ? (
        <div className="mt-2">
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Facebook API error:</AlertTitle>
            {validationCallError}
          </Alert>
        </div>
      ) : null}
    </div>
  );
}
