import React from "react";
import { createPortal } from "react-dom";
import create from "zustand";

type CollapsibleSidebarState = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export const useSidebar = create<CollapsibleSidebarState>((set) => ({
  isOpen: false,
  setIsOpen: (value) => set(() => ({ isOpen: value })),
}));

export const CollapsibleSidebar = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const isOpen = useSidebar((state) => state.isOpen);
  return (
    <div className="flex h-full items-stretch overflow-hidden">
      <div className="h-full min-w-min grow overflow-hidden">
        <main className="h-full">{children}</main>
      </div>
      <div
        className={`h-full w-screen overflow-hidden transition-all duration-300 dark:bg-gray-800 ${
          isOpen
            ? "max-w-0 sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
            : "max-w-0"
        }`}
      >
        <aside
          id="collapsible-sidebar-content"
          className="relative h-full w-screen overflow-y-auto border-l sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
        />
      </div>
    </div>
  );
};

export function SidebarContentPortal({
  isOpen: isOpenProp,
  children,
}: React.PropsWithChildren<{
  isOpen?: boolean;
}>) {
  const { isOpen, setIsOpen } = useSidebar();

  const containerElm = document.getElementById("collapsible-sidebar-content");

  const portal = React.useMemo(() => {
    return containerElm?.ownerDocument.createElement("div");
  }, [containerElm]);

  React.useLayoutEffect(() => {
    if (!portal || !containerElm) return;
    portal.className = "h-full w-full";
    containerElm.appendChild(portal);
    return () => {
      containerElm.removeChild(portal);
    };
  }, [portal, containerElm]);

  const shouldOpen = React.useMemo(() => {
    if (isOpenProp !== undefined) return isOpenProp;
    return React.Children.count(children) > 0;
  }, [children, isOpenProp]);

  React.useEffect(() => {
    if (shouldOpen !== isOpen) {
      setIsOpen(shouldOpen);
    }
  }, [shouldOpen, isOpen, setIsOpen]);

  React.useEffect(() => {
    return () => {
      setIsOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (containerElm && portal) {
    return createPortal(children, portal);
  }
  return null;
}
