import { ComponentProps, createElement } from "react";
import rehypeReact from "rehype-react";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";

const Heading = (props: ComponentProps<"h3">) => {
  return (
    <h3 className="my-3 text-base font-medium first:mt-0 last:mb-0" {...props}>
      {props.children}
    </h3>
  );
};

export const elements = {
  createElement: createElement,
  components: {
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    code: (props: ComponentProps<"code">) => (
      <code
        {...props}
        className="break-words px-0.5 text-red-600 dark:text-red-400"
      />
    ),
    blockquote: (props: ComponentProps<"blockquote">) => (
      <blockquote className="my-2 ml-2 border-l-4 pl-2" {...props} />
    ),
    ul: (props: ComponentProps<"ul">) => (
      <ul
        className="list-disc space-y-1 py-2 pl-5 first:pt-0 last:pb-0"
        {...props}
      />
    ),
    ol: (props: ComponentProps<"ol">) => (
      <ol
        className="list-decimal space-y-1 py-2 pl-5 first:pt-0 last:pb-0"
        {...props}
      />
    ),
    strong: (props: ComponentProps<"strong">) => (
      <strong className="font-semibold" {...props} />
    ),
  },
};

const processor = unified()
  .use(remarkParse)
  .use(remarkRehype)
  .use(rehypeReact, elements);

export function MarkdownMessage(props: { message: string }) {
  return (
    <div className="whitespace-normal">
      {processor.processSync(props.message).result}
    </div>
  );
}
