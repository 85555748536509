import { forwardRef } from "react";

import { Button } from "@/components/elements/Button";
import { EdIcon } from "@/features/ai-assistant";
import cn from "@/helpers/classNames";
import { ComponentProps } from "@tw-classed/react";

export const HelpButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="solid"
      icon={<EdIcon />}
      {...props}
      className={cn(
        props.className,
        "bg-sky-500 text-white hover:bg-sky-500/80 dark:bg-sky-600 dark:hover:bg-sky-600/80",
      )}
    >
      {props.children ?? "Fix with Ed"}
    </Button>
  );
});
