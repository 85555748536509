import { Fragment, useRef } from "react";

import { ErrorIcon } from "@/components/icons/graphic";
import { Dialog, Transition } from "@headlessui/react";

import { useSafeState } from "../../hooks/useSafeState";
import { DangerButton, SecondaryButton } from "./Button";

export default function ConfirmDeleteModal({
  onConfirm,
  onClose,
  show,
  title,
  description,
  extraConfigurations,
}: {
  onConfirm: () => Promise<void>;
  onClose: () => void;
  show: boolean;
  title: string;
  description?: string;
  extraConfigurations?: React.ReactNode;
}) {
  const [state, setState] = useSafeState("loaded");

  const handleConfirm = async () => {
    setState("loading");
    const t = setTimeout(() => setState("loading-visible"), 200);
    await onConfirm();
    clearTimeout(t);
    setState("loaded");
    onClose();
  };

  const handleClose = () => {
    if (state !== "loaded") return;
    onClose();
  };

  const cancelButtonRef = useRef(null);

  const handleDialogClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={show}
        onClick={handleDialogClick}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <ErrorIcon aria-hidden="true" className="h-8 w-8" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Delete {title}
                  </Dialog.Title>
                  <div className="mt-2 space-y-4 text-sm text-muted-foreground">
                    <p>
                      {description
                        ? description
                        : `Are you sure you want to delete the ${title}? This action
                      cannot be undone.`}
                    </p>
                    {extraConfigurations}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <DangerButton
                  disabled={state !== "loaded"}
                  className={`inline-flex w-full justify-center sm:ml-3 sm:w-auto sm:text-sm`}
                  onClick={() => handleConfirm()}
                >
                  {state === "loading-visible" ? "Deleting..." : "Delete"}
                </DangerButton>
                <SecondaryButton
                  disabled={state !== "loaded"}
                  className={`mt-3 inline-flex w-full justify-center sm:mt-0 sm:w-auto sm:text-sm`}
                  onClick={() => handleClose()}
                  ref={cancelButtonRef}
                >
                  Cancel
                </SecondaryButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
