import { ComponentProps, forwardRef } from "react";

import { PlanName } from "@/apollo/types";
import { formatPlanName } from "@/features/billing";
import cn from "@/helpers/classNames";
import { SparklesIcon } from "@heroicons/react/16/solid";

import { Badge } from "../Badge";

export const PlanFeatureBadge = forwardRef<
  HTMLDivElement,
  { planName: PlanName } & ComponentProps<typeof Badge>
>(({ planName, ...props }, ref) => {
  return (
    <Badge
      ref={ref}
      {...props}
      className={cn(
        "bg-black leading-normal text-white shadow-md dark:bg-yellow-600/40",
        props.className,
      )}
    >
      <SparklesIcon className="mr-1 h-3.5 w-3.5" />
      {formatPlanName(planName)}
    </Badge>
  );
});
