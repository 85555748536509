import { useCallback, useEffect, useRef, useState } from "react";

import { GetOAuthUrlDocument } from "@/apollo/types";
import { useSocketEvent } from "@/socket/SocketContext";
import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { useLatestValueRef } from "../../hooks/useLatestValueRef";

type UseOAuthOptions = {
  onSuccess: (connectionId: string) => void;
  onError: (error: Error) => void;
};

type ConnectFn = (options: {
  integrationId: string;
  connectionId?: string;
  data: Record<string, any>;
}) => Promise<void>;

function getError(e: unknown) {
  if (e instanceof Error) {
    return e;
  }
  try {
    return new Error(JSON.stringify(e));
  } catch {
    return new Error(String(e));
  }
}

export function useOAuth(options: UseOAuthOptions) {
  const optionsRef = useLatestValueRef(options);

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  const shouldListenRef = useRef(false);

  useSocketEvent("oauth:token-exchange-error", {
    onMessage(response) {
      options.onError(
        new Error(
          `${response.payload.error} ${response.payload.error_description}`,
        ),
      );
    },
  });

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (shouldListenRef.current === false) {
        return;
      }

      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.type === "oauth_callback") {
        shouldListenRef.current = false;
        window.removeEventListener("message", listener);
        setLoading(false);
        if (event.data.payload) {
          optionsRef.current.onSuccess?.(event.data.payload);
          return;
        }
        if (event.data.error) {
          optionsRef.current.onError?.(new Error(event.data.error));
          return;
        }
      }
    };

    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [optionsRef]);

  const handleOAuth: ConnectFn = useCallback(
    async ({ connectionId, integrationId, data }) => {
      try {
        setLoading(true);

        const response = await client.query({
          fetchPolicy: "no-cache",
          query: GetOAuthUrlDocument,
          variables: {
            connectionId,
            integrationId,
            payload: JSON.stringify(data),
          },
        });

        shouldListenRef.current = true;

        const popup = window.open(
          response.data.getOAuthURL,
          "_blank",
          "popup=yes,width=800,height=600",
        );
        // Check if the popup was blocked
        if (
          !popup ||
          popup.closed ||
          typeof popup == "undefined" ||
          typeof popup.closed === "undefined"
        ) {
          Sentry.captureMessage(
            "Pop-up Window Blocked. Will Redirect to OAuth URL",
            {
              extra: {
                integrationId,
                connectionId,
              },
            },
          );
          // Handle blocked popup (e.g., show a warning to the user)
          optionsRef.current.onError?.(
            new Error("Pop-up window blocked by browser. Will redirect."),
          );
          setTimeout(() => {
            window.location.href = response.data.getOAuthURL;
          }, 300);
        }
      } catch (e) {
        const error = getError(e);
        optionsRef.current.onError?.(error);
        shouldListenRef.current = false;
      } finally {
        setLoading(false);
      }
    },
    [client, optionsRef],
  );

  return { handleOAuth, oAuthLoading: loading };
}
