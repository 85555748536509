import { forwardRef } from "react";

import { Button, IconButton } from "@/components/elements/Button";
import cn from "@/helpers/classNames";
import { ComponentProps } from "@tw-classed/react";

export const PaneActionButton = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof IconButton>
>((props, ref) => {
  const btnProps: ComponentProps<typeof IconButton> = {
    size: "xs",
    variant: "ghost",
    ...props,
    className: cn("px-1.5", props.className),
  };
  if (props.children) {
    return <Button ref={ref} {...btnProps} />;
  }
  return <IconButton ref={ref} {...btnProps} />;
});
