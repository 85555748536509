import { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import { ModelFolder, ModelListItemFragment } from "@/apollo/types";
import { Checkbox } from "@/components/primitives/Checkbox";
import { Input } from "@/components/primitives/input";
import cn from "@/helpers/classNames";
import { useMountEffect } from "@/hooks/useMountEffect";
import { FolderIcon } from "@/pages/ModelTool/components/FolderIcon";
import { useListModels } from "@/pages/ModelTool/hooks/useListModels";
import { useModelFolders } from "@/pages/ModelTool/hooks/useModelFolder";

import { ExpandCollapseButton } from "./ExpandCollapseButton";

type ModelItem = {
  id: string;
  name: string;
  type: "model";
  folderId: string;
  data: ModelListItemFragment;
};

type FolderItem = {
  id: string;
  name: string;
  type: "folder";
  parentFolderId: string | null;
  children: (FolderItem | ModelItem)[];
  data: Omit<ModelFolder, "deletedAt">;
};

type TreeNode = FolderItem | ModelItem;

function sortTree(tree: TreeNode[]): TreeNode[] {
  return tree
    .map((node) => {
      if (node.type === "folder") {
        const sortedChildren = sortTree(node.children);
        return { ...node, children: sortedChildren };
      }
      return node;
    })
    .sort((a, b) => {
      // Sort folders before models
      if (a.type === "folder" && b.type === "model") return -1;
      if (a.type === "model" && b.type === "folder") return 1;
      return a.name.localeCompare(b.name);
    });
}

function useModelsHierarchy() {
  const { models } = useListModels(true);
  const { modelFolders } = useModelFolders();

  return useMemo(() => {
    const foldersMap = new Map<string, FolderItem>();

    modelFolders.forEach((folder) => {
      foldersMap.set(folder.id, {
        id: folder.id,
        name: folder.name,
        type: "folder",
        parentFolderId: folder.parentId ?? null,
        children: [],
        data: folder,
      });
    });

    models.forEach((model) => {
      if (!model.folder) return;
      const folder = foldersMap.get(model.folder.id);
      if (folder) {
        folder.children.push({
          id: model.id,
          name: model.name,
          type: "model",
          folderId: model.folder.id,
          data: model,
        });
      }
    });

    const rootFolders: FolderItem[] = [];
    foldersMap.forEach((folder) => {
      if (folder.parentFolderId) {
        const parentFolder = foldersMap.get(folder.parentFolderId);
        if (parentFolder) {
          parentFolder.children.unshift(folder);
        }
      } else {
        rootFolders.push(folder);
      }
    });
    return {
      data: sortTree(rootFolders),
      resolveFolderTrail: (folderId: string) => {
        const trail: FolderItem[] = [];
        let currentFolder = foldersMap.get(folderId);
        while (currentFolder) {
          trail.unshift(currentFolder);
          currentFolder = foldersMap.get(currentFolder.parentFolderId ?? "");
        }
        return trail;
      },
    };
  }, [models, modelFolders]);
}

export default function ModelsSelector(props: {
  value: ModelListItemFragment[];
  onChange: (models: ModelListItemFragment[]) => void;
}) {
  const { data: treeData, resolveFolderTrail } = useModelsHierarchy();

  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  useMountEffect(() => {
    searchInputRef.current?.focus();
  });

  const [expandedNodes, setExpanded] = useState(() => {
    const folders = props.value.flatMap((model) => {
      if (!model.folder) {
        return [];
      }
      const trail = resolveFolderTrail(model.folder.id);
      return trail.map((folder) => folder.id);
    });
    return new Set<string>(folders);
  });

  const expand = (id: string) => {
    setExpanded((prev) => {
      if (prev.has(id)) return prev;
      return new Set([...prev, id]);
    });
  };

  const collapse = (id: string) => {
    setExpanded((prev) => {
      if (!prev.has(id)) return prev;
      return new Set([...prev].filter((x) => x !== id));
    });
  };

  const toggleExpand = (id: string) => {
    if (expandedNodes.has(id)) {
      collapse(id);
    } else {
      expand(id);
    }
  };

  const [debouncedSearchText] = useDebounce(searchText, 300);

  const filteredTreeData = useMemo(() => {
    if (!debouncedSearchText || debouncedSearchText.length < 2) {
      return treeData;
    }
    const filterTree = (nodes: TreeNode[], searchText: string): TreeNode[] => {
      return nodes
        .map((node) => {
          if (node.type === "folder") {
            const children = filterTree(node.children, searchText);
            return {
              ...node,
              children,
            };
          }
          return node;
        })
        .filter((node) => {
          if (node.type === "folder") {
            return node.children.length > 0;
          }
          return node.name.toLowerCase().includes(searchText.toLowerCase());
        });
    };

    return filterTree(treeData, debouncedSearchText);
  }, [treeData, debouncedSearchText]);

  useEffect(() => {
    if (!debouncedSearchText || debouncedSearchText.length < 2) {
      return;
    }
    const folderIds = new Set<string>();
    const traverse = (nodes: TreeNode[]) => {
      nodes.forEach((node) => {
        if (node.type === "folder" && node.children.length > 0) {
          folderIds.add(node.id);
          traverse(node.children);
        }
      });
    };
    traverse(filteredTreeData);
    setExpanded((prev) => {
      const newExpanded = new Set(prev);
      folderIds.forEach((id) => {
        newExpanded.add(id);
      });
      return newExpanded;
    });
  }, [filteredTreeData, debouncedSearchText]);

  const handleToggleModel = (isChecked: boolean, model: ModelItem) => {
    if (isChecked) {
      props.onChange([...props.value, model.data]);
    } else {
      props.onChange(props.value.filter((m) => m.id !== model.id));
    }
  };

  const renderTree = (nodes: TreeNode[]) => {
    return nodes.map((node) => {
      const isFolder = node.type === "folder";
      const isModel = node.type === "model";
      const isEmptyFolder = isFolder && node.children.length === 0;
      const isChecked =
        node.type === "model" && props.value.some((m) => m.id === node.id);
      return (
        <div key={node.id} className="pt-1">
          {isFolder && (
            <div
              className={cn("flex cursor-pointer items-center gap-2", {
                "opacity-60": isEmptyFolder,
              })}
              onClick={() => !isEmptyFolder && toggleExpand(node.id)}
            >
              <ExpandCollapseButton
                open={expandedNodes.has(node.id)}
                disabled={isEmptyFolder}
              />
              <FolderIcon
                open={expandedNodes.has(node.id)}
                emoji={node.data.emoji ?? undefined}
              />
              <div>{node.name}</div>
            </div>
          )}
          {isModel && (
            <label className="flex cursor-pointer items-center gap-2">
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  handleToggleModel(e.target.checked, node);
                }}
              />
              <span>{node.name}</span>
            </label>
          )}
          {node.type === "folder" && expandedNodes.has(node.id) && (
            <div className="pl-7">{renderTree(node.children)}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="flex grow flex-col">
      <div>
        <Input
          ref={searchInputRef}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search models..."
          className="w-full"
          size="sm"
        />
      </div>
      <div className="mt-2 max-h-96 grow overflow-auto p-1">
        {renderTree(filteredTreeData)}
      </div>
    </div>
  );
}
