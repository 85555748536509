import { useEffect, useRef, useState } from "react";
import {
  CellProps,
  Column,
  IdType,
  Row as RowType,
  SortByFn,
  SortingRule,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowState,
  useSortBy,
  useTable,
} from "react-table";

import { EltSyncsQuery } from "@/apollo/types";
import { ButtonPrimitive } from "@/components/elements/Button";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatTime } from "@/components/elements/FormatTime";
import IntegrationCellSyncFrequency from "@/components/elements/IntegrationCellSyncFrequency";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/elements/Table";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import { Input } from "@/components/primitives/input";
import { EltSyncStatusBadge } from "@/features/elt";
import cn from "@/helpers/classNames";
import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";
import { RadioGroup } from "@headlessui/react";
import {
  ArrowUpIcon,
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { SyncMenu } from "./SyncMenu";
import { ConnectorCell } from "./cells/ConnectorCell";

type DataSource = EltSyncsQuery["eltSyncs"][0];

const globalFilterFn = (
  rows: Array<RowType<DataSource>>,
  columnIds: Array<IdType<DataSource>>,
  filterValue: any,
): Array<RowType<DataSource>> => {
  if (
    filterValue === undefined ||
    (typeof filterValue === "string" && filterValue.trim() === "")
  ) {
    return rows;
  }
  return rows.filter((row) => {
    const d = row.original;
    const fields = [
      d.destinationSchemaName,
      d.sourceConnection?.label,
      d.sourceIntegrationId,
    ];
    if (row.original.sourceIntegrationId.includes("google-sheets")) {
      fields.push(...row.original.sourceStreams.map((x) => x.name));
    }
    return fields
      .filter((value): value is string => typeof value === "string")
      .some((value) => value.toLowerCase().includes(filterValue.toLowerCase()));
  });
};

const getCronExpressionValue = (row: DataSource, desc?: boolean) => {
  const maxMinSortValue = desc
    ? Number.MIN_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER;

  if (row.status === "NOT_STARTED" || row.status === "PAUSED") {
    return maxMinSortValue;
  }
  if (row.orchestrationWorkflow?.id) {
    return cronExpressionToDayjs(
      row.orchestrationWorkflow.cronExpression,
    ).unix();
  }
  if (row.syncInterval) {
    return cronExpressionToDayjs(row.syncInterval).unix();
  }
  return maxMinSortValue;
};

const cronExprSortFn: SortByFn<DataSource> = (rowA, rowB, columnId, desc) => {
  return (
    getCronExpressionValue(rowA.original, desc) -
    getCronExpressionValue(rowB.original, desc)
  );
};

const columns: Column<DataSource>[] = [
  {
    Header: "Connector",
    Cell: ({
      row,
      value: sourceIntegrationId,
    }: CellProps<DataSource, DataSource["sourceIntegrationId"]>) => {
      return (
        <ConnectorCell
          tableNames={row.original.sourceStreams.map(({ name }) => name)}
          integrationId={sourceIntegrationId}
          destinationSchemaName={row.original.destinationSchemaName}
          connectionLabel={row.original.sourceConnection?.label}
        />
      );
    },
    accessor: "sourceIntegrationId",
  },
  {
    Header: "Next sync",
    Cell: ({
      row,
      value: syncInterval,
    }: CellProps<DataSource, DataSource["syncInterval"]>) => {
      if (
        row.original.status === "NOT_STARTED" ||
        row.original.status === "PAUSED"
      ) {
        return <TextMuted>-</TextMuted>;
      }

      const cronExpression = row.original.orchestrationWorkflow?.id
        ? row.original.orchestrationWorkflow.cronExpression
        : syncInterval;
      if (cronExpression) {
        const date = cronExpressionToDayjs(cronExpression);
        return (
          <>
            <Tooltip content={<FormatTime date={date} format="LLL" />}>
              <FormatRelativeTime date={date} className="mb-1 block" />
            </Tooltip>
            <TextMuted
              as="div"
              className="hidden whitespace-nowrap text-xs leading-none md:block"
            >
              <CronDisplayReadable cron={cronExpression} />
            </TextMuted>
          </>
        );
      }

      if (row.original.orchestrationWorkflow?.id)
        return (
          <IntegrationCellSyncFrequency
            syncInterval={row.original.orchestrationWorkflow.cronExpression}
          />
        );
      if (syncInterval) {
        return <IntegrationCellSyncFrequency syncInterval={syncInterval} />;
      }
      return null;
    },
    accessor: "syncInterval",
    sortType: cronExprSortFn,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }: CellProps<DataSource, DataSource["id"]>) => {
      return (
        <div className="whitespace-nowrap">
          <EltSyncStatusBadge eltSyncId={row.original.id} />
        </div>
      );
    },
    width: 150,
    maxWidth: 150,
    disableSortBy: true,
  },
  {
    Header: "",
    Cell: ({ row, value: id }: CellProps<DataSource, DataSource["id"]>) => (
      <div className="flex justify-end">
        <SyncMenu
          syncId={id}
          active={row.original.active}
          sourceId={row.original.sourceConnection?.id ?? ""}
        />
      </div>
    ),
    width: 20,
    maxWidth: 20,
    accessor: "id",
    disableSortBy: true,
  },
  {
    accessor: "createdAt",
  },
  {
    accessor: "active",
    filter: "exact",
  },
];

export function DataTable(props: {
  syncs: DataSource[];
  onRowClick: (
    row: DataSource,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [sorting, setSorting] = useState<SortingRule<DataSource>[]>([
    {
      id: "createdAt",
      desc: true,
    },
  ]);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>();

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    setFilter: setTableFilter,
    setGlobalFilter: setTableGlobalFilter,
    state: { sortBy, globalFilter: tableGlobalFilter, filters: tableFilters },
  } = useTable<DataSource>(
    {
      columns,
      data: props.syncs,
      initialState: {
        sortBy: sorting,
        hiddenColumns: ["createdAt", "active"],
        globalFilter: globalFilter,
      },
      globalFilter: globalFilterFn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState,
  );

  useEffect(() => {
    setSorting(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setGlobalFilter(tableGlobalFilter);
  }, [tableGlobalFilter]);

  return (
    <div className="w-full">
      <div className="flex items-center gap-4 pb-4">
        <div className="relative flex w-full max-w-sm items-center">
          <Input
            ref={inputRef}
            placeholder="Filter data sources..."
            value={globalFilter || ""}
            onChange={(e) => setTableGlobalFilter(e.target.value)}
            size="md"
            className="w-full pl-10 text-sm"
          />
          <div className="absolute left-0 top-0 z-10 flex h-full items-center px-2">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 dark:text-gray-400" />
          </div>
          {globalFilter && globalFilter.trim().length && (
            <div className="absolute right-0 top-0 z-10 flex h-full items-center px-2">
              <ButtonPrimitive
                className="opacity-50 hover:opacity-100"
                onClick={() => {
                  setTableGlobalFilter(undefined);
                  inputRef.current?.focus();
                }}
              >
                <XMarkIcon className="h-5 w-5" />
              </ButtonPrimitive>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <StatusFilterButtons
            value={tableFilters.find((x) => x.id === "active")?.value}
            onChange={(value) => setTableFilter("active", value)}
          />
        </div>
      </div>
      <Table {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    {...header.getHeaderProps(
                      header.getSortByToggleProps({
                        style: {
                          maxWidth: header.maxWidth,
                          minWidth: header.minWidth,
                          width: header.width,
                        },
                      }),
                    )}
                  >
                    <div
                      className={cn("flex items-center whitespace-nowrap", {
                        "font-medium": header.isSorted,
                      })}
                    >
                      {header.render("Header")}
                      {header.canSort && (
                        <div
                          className={cn(
                            "ml-1 inline-flex h-4 w-4 text-gray-500 opacity-40",
                            {
                              "opacity-100": header.isSorted,
                            },
                          )}
                        >
                          {header.isSorted ? (
                            <ArrowUpIcon
                              className={cn({
                                "rotate-180 transition-transform":
                                  header.isSortedDesc,
                              })}
                            />
                          ) : (
                            <ArrowsUpDownIcon />
                          )}
                        </div>
                      )}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={(e) => props.onRowClick(row.original, e)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps({
                        style: {
                          maxWidth: cell.column.maxWidth,
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function StatusFilterButtons(props: {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}) {
  return (
    <RadioGroup<"div", boolean | null>
      value={props.value ?? null}
      onChange={(value) => props.onChange(value === null ? undefined : value)}
    >
      <RadioGroup.Label className="sr-only">Choose a filter</RadioGroup.Label>
      <div className="flex items-center gap-2">
        {[
          { value: null, label: "all" },
          { value: true, label: "Active" },
          { value: false, label: "Inactive" },
        ].map((x) => (
          <RadioGroup.Option
            key={String(x.value)}
            value={x.value}
            className={({ active, checked }) =>
              cn(
                "h-10 cursor-pointer whitespace-nowrap rounded-sm focus:outline-none",
                active ? "ring-2 ring-blue-500 ring-offset-2" : "",
                checked
                  ? "border-transparent bg-blue-600 text-white hover:bg-blue-700"
                  : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-white",
                "rounded-xs flex items-center justify-center border px-3 py-3 text-sm font-medium uppercase",
              )
            }
          >
            <RadioGroup.Label as="p" className="uppercase">
              {x.label}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
