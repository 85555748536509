import { createContext, useContext, useMemo } from "react";

import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationType, hasAbility, useIntegration } from "@/integrations";

import { useCurrentAccount } from "./account";

const DataWarehouseContext = createContext<{
  integration: IntegrationType;
  features: {
    isTransformEnabled: boolean;
    isExtractEnabled: boolean;
    isActivateEnabled: boolean;
  };
} | null>(null);

export function useDataWarehouseContext() {
  const context = useContext(DataWarehouseContext);
  if (!context) {
    throw new Error(
      "useDataWarehouseContext must be used within a DataWarehouseProvider",
    );
  }
  return context;
}

export function DataWarehouseProvider(props: {
  children: React.ReactNode;
  onIntegrationNotFound?: (
    account: ReturnType<typeof useCurrentAccount>,
  ) => void;
}) {
  const account = useCurrentAccount();

  const integration = useIntegration(
    account.dataWarehouse?.integrationId ?? "",
  );

  const features = useMemo(() => {
    if (!integration) {
      return {
        isTransformEnabled: false,
        isExtractEnabled: false,
        isActivateEnabled: false,
      };
    }
    return {
      isTransformEnabled: [
        "snowflake",
        "bigquery",
        "weld-bigquery",
        "postgresql",
      ].includes(integration.id),
      isExtractEnabled: hasAbility(integration, "EltDestinationConnection"),
      isActivateEnabled: hasAbility(integration, "ReverseEltSourceConnection"),
    };
  }, [integration]);

  useMountEffect(() => {
    if (!integration && props.onIntegrationNotFound) {
      props.onIntegrationNotFound(account);
    }
  });

  if (!integration) {
    return null;
  }

  return (
    <DataWarehouseContext.Provider value={{ integration, features }}>
      {props.children}
    </DataWarehouseContext.Provider>
  );
}
