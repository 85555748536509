import { ComponentProps, useEffect, useRef, useState } from "react";

import { useRawViewStatsQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import { Heading } from "@/components/elements/Typography";
import { DataSourceIcon } from "@/components/icons/outline";
import { useDataSourceSlideOver } from "@/components/modules/new-data-source-slideover";
import cn from "@/helpers/classNames";
import { useMixpanel } from "@/monitoring/mixpanel";
import { LinkWithSlug } from "@/routes";
import { FocusTrap } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { CircleStackIcon } from "@heroicons/react/24/outline";

export function DataAvailabilityOverlay() {
  const { data } = useRawViewStatsQuery({
    fetchPolicy: "cache-and-network",
    onCompleted(data) {
      setIsOpen(data.rawViewStats.numFirstSyncCompleted === 0);
    },
  });

  const [isOpen, setIsOpen] = useState(
    data?.rawViewStats.numFirstSyncCompleted === 0,
  );

  const mixpanel = useMixpanel();
  useEffect(() => {
    if (isOpen) {
      mixpanel.track("Model Tool - No Data Available Overlay Shown");
    }
  }, [isOpen, mixpanel]);

  const { onOpen: onOpenDataSourceSlideOver } = useDataSourceSlideOver();
  const connectButtonRef = useRef<HTMLAnchorElement>(null);
  if (!isOpen) {
    return null;
  }
  return (
    <FocusTrap initialFocus={connectButtonRef}>
      <div className="absolute inset-0 z-10">
        <div className="h-full w-full bg-black/5 p-2 backdrop-blur-sm backdrop-saturate-50 dark:bg-white/5"></div>
        <div className="absolute inset-0 z-10 flex items-center justify-center p-2">
          <section className="relative w-full max-w-lg rounded border bg-white p-6 shadow-xl dark:bg-gray-800 md:p-10">
            <header className="flex items-center gap-4 pb-6">
              <NoDataIcon className="h-7 w-7" />
              <Heading className="text-lg">No Data Available Yet</Heading>
            </header>
            <div className="space-y-8">
              <p>
                Transform relies on data from your connected sources — let’s get
                you set up so you can start exploring right away.
              </p>
              <div className="flex justify-center">
                <Button
                  ref={connectButtonRef}
                  as={LinkWithSlug}
                  variant="solid"
                  colorScheme="primary"
                  onClick={() => {
                    onOpenDataSourceSlideOver();
                  }}
                  autoFocus
                  size="lg"
                  to="/sources"
                  icon={<DataSourceIcon />}
                >
                  Connect Your Data Source
                </Button>
              </div>
              <div className="space-y-4">
                <p>
                  Heads up: Your initial sync may take time, so new data won’t
                  appear immediately.
                </p>
                <div className="space-y-2">
                  <p>Need to check sync status?</p>
                  <ul className="ml-1 list-inside list-disc space-y-1">
                    <li>
                      <LinkWithSlug
                        to="/sources"
                        className="underline underline-offset-2"
                      >
                        View Data Sources
                      </LinkWithSlug>
                    </li>
                    <li>
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a
                        href="https://weld.app/docs/account/data-sources"
                        target="_blank"
                        className="underline underline-offset-2"
                      >
                        Learn more about Data Sources
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </FocusTrap>
  );
}

function NoDataIcon(props: ComponentProps<"div">) {
  return (
    <div {...props} className={cn("relative opacity-85", props.className)}>
      <CircleStackIcon />
      <div className="absolute -bottom-1 -right-1 h-2/3 w-2/3">
        <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-600">
          <XMarkIcon className="w-3/4 text-white" />
        </div>
      </div>
    </div>
  );
}
