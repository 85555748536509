import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  useGetSignupQuestionnaireResultQuery,
  useSaveSignupQuestionnaireResultMutation,
} from "@/apollo/types";
import { SuccessIcon } from "@/components/icons/graphic";

import type { QuestionComponentProps } from "./questions";
import * as questionComponents from "./questions";

export function useSignupQuestionnaire() {
  const { data } = useGetSignupQuestionnaireResultQuery();
  const [saveResult] = useSaveSignupQuestionnaireResultMutation();
  return {
    result: data?.getSignupQuestionnaireResult.result,
    saveResult: useCallback(
      async (result: Record<string, any>) => {
        const mergedResult = {
          ...(data?.getSignupQuestionnaireResult.result?.result ?? {}),
          ...result,
        };
        await saveResult({
          variables: {
            input: {
              result: mergedResult,
            },
          },
        });
      },
      [data, saveResult],
    ),
  };
}

export const questions: {
  id: string;
  component: React.FC<QuestionComponentProps<any>>;
}[] = [
  {
    id: "referral",
    component: questionComponents.ReferralQuestion,
  },
  {
    id: "current-data-access",
    component: questionComponents.CurrentDataAccessQuestion,
  },
  {
    id: "intended-use",
    component: questionComponents.IntendedUse,
  },
  {
    id: "sql-familiarity",
    component: questionComponents.SQLFamiliarity,
  },
  {
    id: "connector-count",
    component: questionComponents.ConnectorCount,
  },
];

export function SignupQuestionnaire(props: {
  questions: typeof questions;
  onAnswersChange: (id: string, answers: Record<string, any>) => void;
  onComplete: () => void;
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion: (typeof props.questions)[number] | undefined =
    props.questions[currentQuestionIndex];

  const [answers, setAnswers] = useState<Record<string, any>>({});

  const handleNext = useDebouncedCallback(
    () =>
      setCurrentQuestionIndex(
        (currentQuestionIndex) => currentQuestionIndex + 1,
      ),
    400,
  );

  const isComplete = currentQuestionIndex >= props.questions.length;

  const handleAnswerChange = (value: any) => {
    setAnswers((answers) => ({
      ...answers,
      [currentQuestion.id]: value,
    }));
    props.onAnswersChange(currentQuestion.id, {
      ...answers,
      [currentQuestion.id]: value,
    });
    if (currentQuestionIndex >= props.questions.length - 1) {
      props.onComplete();
    }
    handleNext();
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentQuestion ? currentQuestion.id : "empty"}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {currentQuestion && (
          <currentQuestion.component onChange={handleAnswerChange} />
        )}
        {isComplete && (
          <div className="flex flex-col items-center justify-center">
            <SuccessIcon />
            <p className="mt-5 text-lg font-semibold">Thanks!</p>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
