import React from "react";

import { useCurrentUserWithAccountsLazyQuery } from "@/apollo/types";
import { Button } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import { ErrorIcon, SuccessIcon } from "@/components/icons/graphic";
import { useMountEffect } from "@/hooks/useMountEffect";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useSearch } from "@tanstack/react-location";

async function getErrorMessage(response: Response) {
  let errorMessage = response.statusText;
  try {
    errorMessage = (await response.json()).message;
  } catch {
    errorMessage = await response.text();
  }
  return errorMessage;
}

export function OAuthCallbackPage() {
  const { getAccessTokenSilently } = useAuth0();
  const params = useSearch<{
    Search: {
      url: string;
    };
  }>();

  const [fetchCurrentUser] = useCurrentUserWithAccountsLazyQuery();

  const [fallbackState, setFallbackState] = React.useState<
    | {
        statusMessage: string;
        isResponseError: boolean;
        isNetworkError?: never;
      }
    | {
        statusMessage: string;
        isResponseError?: never;
        isNetworkError: boolean;
      }
    | null
  >(null);

  useMountEffect(async () => {
    try {
      if (!params.url) {
        return;
      }
      const response = await fetch(params.url, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });
      if (!response.ok) {
        const errorMessage = await getErrorMessage(response);
        if (window.opener) {
          window.opener.postMessage({
            type: "oauth_callback",
            error: errorMessage,
          });
        } else {
          setFallbackState({
            statusMessage: errorMessage,
            isResponseError: true,
          });
        }
      } else {
        const connection: { id: string; accountId: string } | undefined =
          await response.json();

        if (connection && typeof connection.id === "string") {
          if (window.opener) {
            // If the window was opened by another window, post a message to the parent window
            window.opener.postMessage({
              type: "oauth_callback",
              payload: connection.id,
            });
            window.close();
          } else {
            // If the window was opened directly, redirect to the settings page of the connection
            const { data: userData } = await fetchCurrentUser();
            const account = userData?.current?.accounts?.find(
              (x) => x.id === connection.accountId,
            );
            window.location.href = !account
              ? "/"
              : `/${account.slug}/settings/connections/${connection.id}?authFlowSuccess=true`;
          }
        }
      }
    } catch (e) {
      setFallbackState({
        statusMessage: e instanceof Error ? e.message : "Unknown error",
        isNetworkError: true,
      });
    }
  });

  return (
    <div className="flex h-screen items-center justify-center">
      {fallbackState ? (
        <div className="flex max-w-sm flex-col items-center gap-4">
          {fallbackState.isResponseError || fallbackState.isNetworkError ? (
            <ErrorIcon className="h-16 w-16" />
          ) : (
            <SuccessIcon className="h-16 w-16" />
          )}
          <span>{fallbackState.statusMessage}</span>
          {fallbackState.isNetworkError && (
            <span className="text-center text-sm">
              This issue might be caused by a browser extension such as an{" "}
              <span className="font-bold">adblocker</span>. Please pause the
              extension and try again.
            </span>
          )}
          <Button as={Link} to={"/"} variant="ghost" size={"sm"}>
            Back to account
          </Button>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
}
