import { useState } from "react";
import { LocationGenerics } from "routes";

import {
  ListOrchestrationWorkflowsDocument,
  useDeleteWorkflowMutation,
} from "@/apollo/types";
import { DangerButton } from "@/components/elements/Button";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { DeleteIcon } from "@/components/icons/graphic";
import { useToast } from "@/providers/ToastProvider";
import { useMatch, useNavigate } from "@tanstack/react-location";

import { OrchestrationTabsLayout } from "./OrchestrationTabsLayout";

const defaultFallbackCron = "0 0 * * *";

const WorkflowSettings = (props: {}) => {
  const toast = useToast();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const match = useMatch<LocationGenerics>();

  //const [] = useDEleteWorkflow
  const navigate = useNavigate();

  const [deleteWorkflow, deleteWorkflowMutation] = useDeleteWorkflowMutation({
    variables: {
      workflowId: match.params.workflowId,
      cronSchedule: defaultFallbackCron,
    },

    onCompleted: () => {
      toast(
        "Orchestration deleted",
        "Successfully removed orchestration",
        "success",
      );
      navigate({ to: "../.." });
    },
    onError: (error) => {
      toast(
        "Error deleting orchestration",
        error.message ??
          "Something went wrong trying to delete the orchestration.",
        "error",
      );
    },
    refetchQueries: [{ query: ListOrchestrationWorkflowsDocument }],
    awaitRefetchQueries: true,
    update: (cache) => {
      cache.evict({ id: `OrchestrationWorkflow:${match.params.workflowId}` });
    },
  });

  const onDelete = async () => {
    if (deleteWorkflowMutation.loading) return;
    await deleteWorkflow();
  };

  return (
    <OrchestrationTabsLayout current="settings">
      <div className="flex w-full items-center justify-center">
        <div className="mt-6 flex w-96 flex-col items-center justify-center text-center dark:text-white">
          <DeleteIcon className="mb-6 w-20" />
          <h2 className="mb-6 text-2xl font-medium leading-tight">
            Delete this orchestration
          </h2>
          <p className="mb-6">
            <span>
              All jobs running in this orchestration will be returned to a
              independent schedule updating{" "}
              <CronDisplayReadable
                className="font-medium"
                cron={defaultFallbackCron}
              />
            </span>
          </p>
          {/* <p className="mb-6">
            <span>x tables affected...</span>
          </p> */}
          <ConfirmDeleteModal
            title="this orchestration"
            onConfirm={onDelete}
            show={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
          />
          <DangerButton onClick={() => setShowConfirmModal(true)}>
            Delete this orchestration
          </DangerButton>
        </div>
      </div>
    </OrchestrationTabsLayout>
  );
};

export default WorkflowSettings;
