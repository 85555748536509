import { useCallback } from "react";
import { LocationGenerics } from "routes";

import { useNavigate } from "@tanstack/react-location";

type SlideOverInputOptions = {
  syncId: string;
};

export function useViewDataSourceSlideOver() {
  const navigate = useNavigate<LocationGenerics>();
  return {
    onOpen: useCallback(
      (options: SlideOverInputOptions) => {
        navigate({
          search: (old) => {
            return {
              ...old,
              viewDataSource: options.syncId,
            };
          },
        });
      },
      [navigate],
    ),
    onClose: useCallback(() => {
      navigate({
        search: (old) => {
          return {
            ...old,
            viewDataSource: undefined,
          };
        },
      });
    }, [navigate]),
    getLinkProps: (options: SlideOverInputOptions) => {
      return {
        viewDataSource: options.syncId,
      };
    },
  };
}
